/* eslint-disable react/no-danger */
import { useStaticQuery, graphql } from "gatsby";
import React from "react";

export default function ProductDimensionsImage({
  diagramsString,
  // diagramsData,
}) {
  const staticQuery = useStaticQuery(graphql`
    query HeaderQuery {
      allPlainText {
        nodes {
          content
          parent {
            ... on File {
              name
            }
          }
        }
      }
      # allFile {
      #   nodes {
      #     base
      #   }
      # }
    }
  `);

  // const staticQueryOld = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     allFile {
  //       nodes {
  //         childPlainText {
  //           content
  //         }
  //         base
  //       }
  //     }
  //   }
  // `);

  const diagramsData = staticQuery.allPlainText.nodes;

  const filenames = [];
  const svgs = [];

  if (diagramsString) filenames.push(...diagramsString.split(","));
  if (filenames.length < 1) return <></>;

  let columns = 1;
  if (filenames.length === 2) columns = 2;
  if (filenames.length === 3) columns = 3;
  if (filenames.length > 3) columns = 2;

  const convertDiagram = (rawSVG, filename) => {
    const slug = filename.replaceAll(" ", "-");
    const convertedSVG = rawSVG
      .replaceAll('fill="#515151"', 'class="yellowHack" fill="#515151"')
      .replaceAll(' width="1000" height="1000"', "")
      .replaceAll("clip0", `clip0-${slug}`)
      .replaceAll("clip1", `clip1-${slug}`)
      .replaceAll('clip-path="url(#', `clip-path="url(#${slug}`)
      .replaceAll('clipPath id="', `clipPath id="${slug}`)
      .replaceAll("<path ", '<path vector-effect="non-scaling-stroke" ')
      .replaceAll("<rect ", '<rect vector-effect="non-scaling-stroke" ');
    return convertedSVG;
  };

  const getDiagram = (filename) => {
    if (filename) {
      const diagram = diagramsData.find((raw) => raw.parent.name === filename);
      if (diagram && diagram.content) {
        const rawSVG = diagram.content;
        const convertedSvg = convertDiagram(rawSVG, filename);
        svgs.push(convertedSvg);
      }
    }
  };

  filenames.forEach((filename) => getDiagram(filename));

  // return (
  //   <div
  //     className={`dimensions-diagrams grid-standard grid-fixed-1 grid-fixed-mxl-${columns}`}
  //   >
  //     {svgs.length > 0
  //       ? svgs.map((svg, i) => (
  //           <div key={`dimensions-diagram-${i}`} className="dimensions-diagram">
  //             <div
  //               className="dimensions-diagram-inside"
  //               dangerouslySetInnerHTML={{ __html: svg }}
  //             />
  //           </div>
  //         ))
  //       : null}
  //   </div>
  // );

  // const validSvgs = svgs.filter((svg) => !svg.includes("Adobe Illustrator"));

  const validSvgs = svgs;

  return validSvgs.length > 0 ? (
    <div
      className={`dimensions-diagrams grid-standard grid-fixed-1 grid-fixed-mxl-${columns}`}
    >
      {validSvgs.map((svg, i) => (
        <div key={`dimensions-diagram-${i}`} className="dimensions-diagram">
          <div
            className="dimensions-diagram-inside"
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        </div>
      ))}
    </div>
  ) : null;
}
