import React from "react";
import getDistributionNumerical from "../utils/getDistributionNumerical";

export default function DistributionList({ distributions }) {
  if (!distributions) {
    return <div />;
  }

  // Get values for each distribution
  const sets = [];
  distributions.forEach((distribution) => {
    sets.push({
      label: distribution,
      value: getDistributionNumerical(distribution),
    });
  });

  // Sort by value, then label
  // list.sort((a, b) => (a.color > b.color) ? 1 : (a.color === b.color) ? ((a.size > b.size) ? 1 : -1) : -1 )
  sets.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value === b.value) {
      if (a.label > b.label) {
        return 1;
      }
      return -1;
    }
    return -1;
  });

  if (!sets || sets.length < 1) {
    return <li>-</li>;
  }

  return (
    <>
      {sets.map((d) => (
        <li key={`Distribution-${d.label}`}>{d.label}</li>
      ))}
    </>
  );
}
