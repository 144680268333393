import { getSrc } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

export default function SeoImageSrc(filename) {
  const imageSeoQuery = useStaticQuery(graphql`
    query imageSeoQuery {
      allImageSharp {
        nodes {
          gatsbyImageData(
            formats: JPG
            jpgOptions: { quality: 75 }
            sizes: "(min-width: 2000px) calc(25vw - 69px), (min-width: 1400px) calc(33.28vw - 71px), (min-width: 1100px) calc(40vw - 74px), (min-width: 580px) 500px, 89.23vw"
            placeholder: BLURRED
          )
          parent {
            ... on File {
              relativePath
            }
          }
        }
      }
    }
  `);
  const productImages = imageSeoQuery.allImageSharp.nodes;

  if (!filename || !productImages || productImages.length < 0) return null;

  const imageData = productImages.find(
    (x) => x.parent.relativePath === filename
  );
  if (!imageData) return null;
  const src = getSrc(imageData);
  if (!src) return null;

  // TODO use sitemetadata
  return `https://ratio.lighting${src}`;
}
