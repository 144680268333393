import { Link } from "gatsby";
import React from "react";

export default function ProductBreadcrumb({ breadcrumb }) {
  if (!breadcrumb || breadcrumb.length < 1) return;
  return (
    <div className="">
      <ul className="breadcrumb-list">
        {breadcrumb.map((crumb, i) => (
          <li key={`crumb-${i}`}>
            <Link to={crumb.path}>{crumb.name}</Link>
          </li>
        ))}
      </ul>
      <hr className="mt-medium mb-large my-ml-small" />
    </div>
  );
}
