// import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ProductImage from "./ProductImage";

export default function FeatureGeneric({ filename, alt }) {
  const imageQuery = useStaticQuery(graphql`
    query imageQuery {
      allImageSharp {
        nodes {
          gatsbyImageData(
            formats: JPG
            jpgOptions: { quality: 75 }
            sizes: "(min-width: 2000px) calc(25vw - 69px), (min-width: 1400px) calc(33.28vw - 71px), (min-width: 1100px) calc(40vw - 74px), (min-width: 580px) 500px, 89.23vw"
            placeholder: BLURRED
          )
          parent {
            ... on File {
              relativePath
            }
          }
        }
      }
    }
  `);

  const productImages = imageQuery.allImageSharp.nodes;

  return (
    <div className="feature-generic">
      <div className="primary-image max-width-500 max-width-mxl-none">
        <ProductImage
          filename={filename}
          alt={`${alt} - ${filename}`}
          productImages={productImages}
        />
      </div>
    </div>
  );
}
