export default function getDistributionNumerical(string) {
  const matchWW = string.match(/WW/i);
  const matchWallWash = string.match(/Wall Wash/i);

  if (matchWW || matchWallWash) {
    return 999;
  }

  const matches = string.match(/(\d+)/g);
  if (matches) {
    return matches.reduce((acc, num) => acc * parseInt(num, 10), 1);
  }
}

// Old does not cater for 10x60 values

// export default function getDistributionNumerical(string) {
//   const matches = string.match(/(\d+)/);
//   const matchWW = string.match("WW", "i");
//   const matchWallWash = string.match("Wall Wash", "i");

//   if (matchWW) {
//     return 999;
//   }

//   if (matchWallWash) {
//     return 999;
//   }

//   if (matches) {
//     return parseInt(matches[0]);
//   }
// }
