import { Link } from "gatsby";
import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ProductBreadcrumb from "./ProductBreadcrumb";

export default function SubHeaderGeneric({
  productName,
  links,
  breadcrumb,
  configureButton,
  pathname,
}) {
  const handleEnquireButton = (event) => {
    // ???
  };

  return (
    <header className="px-medium pt-medium pt-ml-small sticky">
      {breadcrumb && breadcrumb.length > 0 && (
        <ProductBreadcrumb breadcrumb={breadcrumb} />
      )}
      <nav className="ms-grid-fixed-1 mm-grid-fixed-2 grid-align-center">
        {productName && productName.length > 0 && (
          <ul className="productName-list fs-large fs-ml-xl fs-mxxxl-xxl">
            {productName.map((line, i) => {
              let t4 = null;
              if (i === 0) {
                t4 = "fw-medium";
              }
              return (
                <li key={`productName-${line.name}`} className={t4}>
                  {line.path ? (
                    <Link to={line.path}>{line.name}</Link>
                  ) : (
                    <>{line.name}</>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {links && links.length > 0 && (
          <ul className="hide-mobile-only nav-list-horizontal nav-list-right ml-fs-large">
            {configureButton && (
              <li>
                {pathname && (
                  <AnchorLink
                    to={`${pathname}#configurator`}
                    title="Configure"
                    className="capsule-link button-capsule-link"
                    stripHash
                  />
                )}
                {/* <button
                  type="button"
                  className="button-capsule-link"
                  onClick={handleEnquireButton}
                >
                  Configure
                </button> */}
              </li>
            )}

            {links.map((link) => (
              <li key={`links-${link.name}`}>
                <a
                  href={link.path}
                  className="capsule-link"
                  download={link.download ? link.download : null}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        )}
        {/* {categories && categories.length > 0 && (
          <ul className="hide-ms-only nav-list-horizontal nav-list-right ml-fs-large">
            {categories.map((crumb) => (
              <li key={`categories-${crumb.name}`}>
                <Link to={crumb.path} className="capsule-link">
                  {crumb.name}
                </Link>
              </li>
            ))}
          </ul>
        )} */}
      </nav>
      <hr className="mt-medium mb-large my-ml-small" />
    </header>
  );
}
